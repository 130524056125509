const comic2Images = [
  {
    original: '/images/comic2/page1.jpg',
    thumbnail: '/images/comic2/thumb1.jpg',
    description: 'Comic 2 - Page 1',
    originalAlt: 'Comic 2 - Page 1',
  },
  {
    original: '/images/comic2/page2.jpg',
    thumbnail: '/images/comic2/thumb2.jpg',
    description: 'Comic 2 - Page 2',
    originalAlt: 'Comic 2 - Page 2',
  },
  // Add more images as needed
];

export default comic2Images;
