import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import './ComicViewer.css';

// Example import of comic images (adapt based on your file structure)
import comic1Page1 from '../images/comic1/page1.jpg';
import comic1Page2 from '../images/comic1/page2.jpg';
import comic2Page1 from '../images/comic2/page1.jpg';
import comic2Page2 from '../images/comic2/page2.jpg';

function ComicViewer({ images, title }) {
  const [open, setOpen] = useState(false);

  // Define your slides (images)
  const slides = [
    { src: comic1Page1, alt: 'Comic Page 1' },
    { src: comic1Page2, alt: 'Comic Page 2' },
    { src: comic2Page1, alt: 'Comic Page 1' },
    { src: comic2Page2, alt: 'Comic Page 2' },
    // Add more images as needed
  ];

  const thumbnailsRef = React.useRef(null);

  return (
    <div className="ComicViewer">
      <h1>{title}</h1>
      {/* Button to open the lightbox */}
      <button onClick={() => setOpen(true)}>Open Comic</button>
      
      {/* Lightbox Component */}
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={slides}
        plugins={[Zoom, Thumbnails]}
        thumbnails={{ rev: thumbnailsRef }}
        on={{
          click: () => {
           (thumbnailsRef.current?.visible
             ? thumbnailsRef.current?.hide
             : thumbnailsRef.current?.show)?.();
          },
        }}
      />
    </div>
  );
}

export default ComicViewer;
